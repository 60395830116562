.principal__factoring {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.form-factoring {
  width: 500px;
  background: #f8f9fd;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  transition: height 2s ease;
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
  /* height: 570px; */
  margin-right: 10vh;
}
.container__text__factoring {
  display: flex;
  position: relative;
  width: 40%;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 5%;
  margin-top: 7%;
  /* top: 100px; */
}

.container__text__factoring h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 48px;
  margin: 5px;
}

.container__text__factoring p {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 36px;
  margin: 0;
}
.container-form__factoring {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-top: -10%;
}
.input__rut__factoring {
  display: flex;
  justify-content: center;
}

.container__rut__factoring {
  display: flex;
  width: 100%;
  gap: 13px;
}

.button-factoring {
  width: 27%;
  height: 40px;
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  border-radius: 10px;
  border: none;
  color: #ffffff;
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.button-factoring:disabled {
  background: gray;
}

.factoring-container {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  margin-top: 3%;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10%;
}

@media (max-width: 1000px) {
  .factoring-container {
    width: 100%;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    align-items: center;
    padding: 20px;
  }
}

@media (max-width: 800px) {
  .principal__factoring {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container__text__factoring {
    width: 100%;    
    margin-top: 10%;
  }
  .form-factoring {
    margin-right: 0;
  }
}

@media (max-width: 550px) {
  .container__text__factoring {
    width: 90%;
    margin-top: 20%;
  }
}

@media (max-width: 320px) {
  .container__text__factoring {
    margin-top: 30%;
  }
}
