.validating_screen_container{
    align-items: center;
    justify-content: center;
}

.validating_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

.validating_container h1{
    color: var(--secondary-color);
}