.table_item {
  padding: 1.3em 2em;
  font-family: 'Montserrat';
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 1.1em;
}

.table_item_data {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 1em;
  flex-wrap: wrap;
}

.item_data_title {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600 !important;
  color: var(--secondary-color);
}

.item_data_value p {
  margin: 0;
  color: black !important;
  font-weight: 500;
  text-align: end;
}

.item_data_actions {
  display: flex;
  align-self: flex-end;
  gap: 1em;
}

.item_data_action {
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
  padding: 10px;
  border-radius: 10%;
  font-size: 0.9em;
}

.item_data_action * {
  margin: 0;
}

.round {
  padding: 8px;
  border-radius: 50%;
}

.item_data_action svg {
  color: white !important;
}

.a {
  margin-top: 20em;
}
