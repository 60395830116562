/* .newsletter_container *{
    margin: 0;
    padding: 0;
  
} */

.newsletter_modal .modal_header{
    background-color: var(--primary-color);
    border-radius: 15px 15px 0 0;
    padding: 20px;
    align-items: center;
    flex-direction: column-reverse;
}

.newsletter_modal .modal_header h1{
    margin: 0;
    color: white;
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
    width: 90%;
}

.newsletter_modal .modal_header svg{
    display: none
}



.newsletter_modal .modal_container{
    padding: 0 !important;
    border: none;
}

.newsletter_modal .step_container{
    padding: 20px;
    background-color: #F6EDED;
}

.newsletter_modal .modal_content{
    border-radius: 15px;
}

.newsletter_container div{
    width: 100%;
    height: fit-content;
    margin: 0;
}

.newsletter_title_container{
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0.5rem 0 !important;
}

/* .newsletter_title_container h2{
    color
} */


.newsletter_container button {
    border: none;
    background-color: #fff;
    padding: 0.5rem 1rem;
    outline: none;
    border-radius: var(--border-radius);
    font-size: 1.2rem;
    color: #0611f2;
    cursor: pointer;

}

.newsletter_container button svg {
    font-size: 1.3rem;
    margin-right: 0.6rem;
    color: #0611f2;
}


.newsletter_form_content{
    gap: 1.2rem;
}


