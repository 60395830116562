.passwordChange_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.passwordChange__error {
  color: red;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: -2.5rem;
  margin-left: 20px;
}

.passwordChange__actions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-inline-start: 10%;
  padding-inline-end: 10%;
}
.input__password__change {
  position: relative;
  padding: 6px 40px;
  margin: 0;
  line-height: 20px;
}
.input__password__change .input__title {
	margin-top: 0px;
}


@media(max-width: 455px ){
  .passwordChange__actions Button{
    width: 40%;
 }
}
