.member__title{
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  color: #00367B;
}

.owners_table__header {
   display: flex;
   width: 100%;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
 }
 
 .owners_table__header button{
   width: 1.5em;
   height: 1.5em;
   padding: 0.8em;
   border: none;
   font-size: 1.5em;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
 }

.remove_owner_actions {
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: flex-end;
}


.MuiTableCell-paddingCheckbox:last-child {
   color: var(--secondary-color) !important;
}

@media(max-width: 700px){
   .owners_table__header {
     font-size: 12px;
   }
 }
