.landing_main{
    max-width: 1200px;
    
    gap: 3rem;
    padding-top: 5rem ;
    margin: 0 auto;
    display: flex;
    align-items: self-start;
    justify-content: center;
}
.algo_main{
    max-width: 1200px;
    width: 80%;    
    margin: 0 auto;  
}
.algo_main h1{
  color: #1251ae;
  font-weight: 700;
  text-align: center;
  font-size: 3rem;
  margin-top: 12rem;
}
.content_landing{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
}
.content_landing img{
    height: 21rem;
}
.content_p{    
    text-align: justify;
    font-size: 1.3rem;
    margin-bottom: 10rem;
}
.content_main{    
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.landing_main .contact__container__index {

    padding:  0 ;
}
.landing_main .form-contact-index {
    width: 84%;
    margin-left: 1rem;
}
@media (max-width: 700px) {
    .landing_main {
      flex-direction: column;
    }
    .landing_main .form-contact-index {
      width: 100%;
    }
    .landing_main .contact__container__index {
      order: 1;
    }
    .content_main {
      order: 2;
    }
  }
  