.about__container {
   width: 100%;
   min-height: 100vh;
   margin-top: 11vh;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
}

.about__title {
   width: 88%;
   margin: 50px auto;
   margin-bottom: 200px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
}

.about__title__main {
   margin: 10px;
   color: var(--primary-color);
   font-size: 2rem;
   text-align: center;
}

.about__title__secondary {
   margin: 10px;
   color: #33363b;
   font-weight: 400;
   opacity: 0.6;
   font-size: 1.3rem;
   text-align: center;
}

.about__profile-card__container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
}

.about__profile-card {
   flex: 1 1 1;
   /* min-width: 300px; */
   max-width: 350px;
   width: 421px;
   margin: 0 0.5rem;
   /* height: 370px; */
   max-height: 420px;
   background: #f8f9fd 0% 0% no-repeat padding-box;
   border: var(--border);
   text-align: center;
   /* box-shadow: 0px 5px 20px #0000003b; */
   border-radius: var(--border-radius);
   position: relative;
   box-sizing: border-box;
   padding: 30px;
   margin-bottom: 100px;
}

.profile-card__image {
   padding: 0;
   margin: 0;
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
   top: -110px;
   width: 50%;
   height: 50%;
   max-width: 150px;
   max-height: 150px;
}

.profile-card__image img {
   width: 100%;
   height: 100%;
   object-fit: contain;
   border-radius: 10%;
}

.profile-card__title {
   text-align: center;
   font: normal normal bold 20px Open Sans;
   font-size: 1.5rem;
   letter-spacing: 0px;
   color: var(--primary-color);
   margin-top: 50px;
   margin-bottom: 10px;
}

.profile-card__subtitle {
   font: normal normal normal 16px Open Sans;
   letter-spacing: 0px;
   color: #5c5c5c;
   margin-top: 10px;
   background-color: #eef2fd;
   border-radius: 21px;
   font-size: 1rem;
   display: inline;
   padding: 0.5rem 0.9rem;
}

.profile-card__paragraph {
   font: normal normal normal 16px Open Sans;
   letter-spacing: 0px;
   color: #5c5c5c;
   text-align: justify;
   font-size: 1rem;
   margin-top: 2rem;
   line-height: 1.4rem;
}

.profile-card__paragraph-icon {
   color: #fff;
   background-color: #1273b0;
   padding: 0.4rem;
   box-sizing: content-box;
   border-radius: 0.2rem;
}

.profile-card__paragraph-icon svg {
   background-color: red;
   color: red;
}

.about__secondary-title {
   width: 100%;
   /* height: 300px; */
   display: flex;
   flex-direction: column;
   align-items: center;
   /* margin-top: -30px; */
   text-align: center;
   background: #f8f9fd;
   padding: 3rem 0;
}

.about__secondary-title h1 {
   color: var(--primary-color);
   font-size: 2.1rem;
   font-weight: 600;
   margin-bottom: -7px;
}

.about__bottom__container {
   width: 90%;
   /* height: 600px; */
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
   padding: 0 2rem;
   border-radius: var(--border-radius);
   border: var(--border);
   background: linear-gradient(180deg, #f8f9fd 0%, #eef7ff 100%);
   box-sizing: border-box;
   margin: 4rem 0;
}

.about__bottom__container__left-size {
   flex: 1;
   min-width: 300px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.about__bottom__container__left-size h1 {
   color: var(--primary-color);
   font-size: 2rem;
   margin: 10px auto;
}

.about__bottom__container__left-size p {
   font-size: 1.3rem;
   color: #33363b;
   font-weight: 400;
   opacity: 0.6;
   margin: 7px auto;
   line-height: 1.9rem;
}

.about__bottom__container__right-size {
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: center;
}

.about__bottom__container__right-size figure {
   margin: 0;
   padding: 0;
   display: flex;
   justify-content: center;
   align-items: center;
}

.about__bottom__container__right-size figure img {
   width: 90%;
   /* min-width: 340px; */
   object-fit: cover;
   object-position: center;
}


/* seccion somos */

.about__container__description {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   padding: 3rem 0;
   /* background-color: rgba(180, 227, 252, 0.422); */
}

.about__container__description h1 {
 color:#00367B;
font-style: normal;
font-size: 40px;
}

.about__container__description p {
   width: 70%;
   font-weight: 500;
   color: #5c5c5c;
   font-size: 18px;
   line-height: 1.9rem;
}


.aboutUs_timeline_container {
   display: flex;
   background-color: #eef7ff;
   gap: 1rem;
   border: var(--border);
   width: 100%;
   justify-content: center;
}


.about__container__description-company {
   width: 50%;
   /* background-color: rgb(208, 205, 205); */
}

.about__container__description-company h2 {
   font-size: 1.8rem;
   color: var(--primary-color);
}

.about__container__description-company p {
   font-weight: 400;
   color: #33363b;
   line-height: 1.9rem;
}

.about__container__description-company .p-bolder {
   font-weight: 500;
   font-size: 1.3rem;
}
/* borrar */

@media (max-width: 600px) {
   .about__bottom__container {
      margin-top: 3rem;
      margin-bottom: 20px;
      /* height: 740px; */
   }

   .about__bottom__container__left-size {
      /* padding-left: 25px;
      padding-right: 25px; */
      text-align: center;
   }
}

@media (max-width: 1100px) {
   .about__profile-card {
      margin-bottom: 10rem;
   }
}

  
