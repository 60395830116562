.welcome__container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #304F97;
  font-weight: 600;
  text-align: center;
}

.welcome__title {
  font-size: 1.9rem; 
  margin-top: 5px;
  margin-bottom: 5px;
}

.welcome__subtitle {
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.welcome__text {
  font-size: .9rem;
}

.welcome__image {
  width: 300px;
  height: 180px;
}

.welcome__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.welcome__button {
  height: 40px;
  background-color: #0065FF;
  color: #FFFFFF; 
  font-size: .85rem;
  font-weight: bold;
  letter-spacing: 0px;
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid transparent;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  padding-left: 30px;
  padding-right: 30px;
}