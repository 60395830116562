.form__container__addmember {
  display: flex;
  background: #ffffff;
  border-radius: 20px;
  position: relative;
  padding: 3rem 3rem 0;

  box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.addmember__title {
  display: flex;
  width: 100%;
  font-size: 24px;
  color: #304f97;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0px;
  margin-bottom: 15px;
  margin-top: 25px;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}
.btn-data {
  width: 180px;
  height: 40px;
  color: #3067b8;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border: none;
  background: #ffffff;
  border: 1px solid #3067b8;
  border-radius: 10px;
}

.container-btn-data {
  display: flex;
}

.select__addMember {
  position: relative;
  width: 180px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #3067b8;
  border-radius: 10px;
  top:-9px;
}

.stick-wizard-step2 {
  display: flex;
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  width: 420px;
  height: 16px;
  border-radius: 30px;
  transition: width 2s;
}
.title-logo {
  display: flex;
  margin-top: -20px;
 
  align-items: center;
}

.title-logo img {
  margin-left: 40px;
  height: 3rem;
}

.btn-add-later-step2 {
  width: 180px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #1251ae;
  border-radius: 10px;
  color: #1251ae;
  margin-top: -10px;
  margin-left: 34%;
}

.container-forms-addmember {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
}

.btn-add-member {
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  color: #f5f9ff;
  width: 122px;
  height: 40px;
  border-radius: 10px;
  margin-top: 30px;
  border: none;
}
.resumen {
  width: 376px;
  height: 600px;
  background: #f8f9fd;
  border: 1px solid #9f9f9f;
  border-radius: 20px;
}

.container-resumen {
  display: flex;
  flex-direction: row;
}

.info__resumen {
  margin-left: 10%;
  margin-top: 5%;
  width: 187px;
  height: 50px;
  color: #00367b;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
}

.name__resumen {
  margin-top: 25px;
  margin-left: 10%;
  font-family: "Inter", sans-serif;
  color: #7a7a7a;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.container__info__resumen {
  width: 100%;
  display:flex;
  color: #9F9F9F;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  flex-direction: column;
  line-height: 20px;
  margin-inline:10%;
}

.container__buttons__add {
  display: flex;
  width: 63%;
  margin-left: 8%;
  justify-content: flex-end;
}
.btn__member {
  width: 210px;
  height: 40px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  background: #ffffff;
  border: 1px solid #1251ae;
  border-radius: 10px;
  color: #1251ae;
  margin-left: 1%;
}

@media(max-width: 1200px){
  .resumen{
    display: none;

  }
}
