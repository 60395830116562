.input__container {
  width: 100%;
}

.input__title {
  font-size: 0.92em;
  font-weight: 600;
  letter-spacing: 0;
  color: #666363;
  margin: 0;
  margin-bottom: -0.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: 17px;
  margin-left: 8px;
  color: #999999;
}

input,
.input__container select {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  width: 100%;
  padding: 8.5px 14px;
}

.input_text_container {
  position: relative;
  width: 100%;
}

.input_error {
  animation-name: error;
  animation-duration: 0.3s;
}

.input_error .input__title {
  color: crimson;
}

.input_error input,
.input_error select,
.input_error {
  border-color: #ff000061 !important;
  color: #ab2c2cbf !important;
}

.input_error input::placeholder,
.input_error select::placeholder,
.input_error::placeholder {
  color: #ff000075 !important;
}

.rut_input_container {
  position: relative;
}

.invalid_rut {
  color: red;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: 60px;
  right: 4px;
}

.input_icon_container {
  position: absolute;
  top: 5px;
  right: 15px;
  cursor: pointer;
}

/* .input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* .input[type='number'] {
  -moz-appearance: textfield;
} */

@keyframes error {
  0% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(5px);
  }
  70% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
