.cards-info-patrimony{
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: -30px;
  margin-right: 10px;
  justify-content:flex-start;
  margin-top: 50px;
  margin-right: 200px;
  margin-left: 30px;
}

.cards-info-patrimony img{
    width: 25%;
    margin: 50px;
}


@media (max-width: 1000px) {
  .cards-info-patrimony {
    display: none;
  }
}
