.MuiPaper-root * {
  font-family: "Inter", sans-serif !important;
}

.MTableToolbar-title-9 h6 {
  font-family: "Inter", sans-serif !important;
  color: var(--secondary-color);
}

@media (max-width: 500px) {
  .MuiToolbar-root {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .MTableToolbar-title-9 {
    margin: 1em 0 !important;
  }

  .MuiFormControl-root {
    width: 100% !important;
    padding: 0 !important;
  }

  .MuiInput-root {
    margin-bottom: 1em !important;
  }

  .MuiInput-underline:before {
    border: none !important;
    display: none !important;
  }

  .Component-paginationSelectRoot-4 {
    margin-left: auto !important;
    margin-right: 0.5em !important;
  }

  .MTablePaginationInner-root-14 {
    margin: 0 auto !important;
  }
}

.MuiIconButton-root {
  padding: 6px !important;
}
.MuiSelect-icon {
  position: unset !important;
}
