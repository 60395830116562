.modal_container {
  width: 60%;
  max-width: 550px;
  max-height: 75vh;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0px 5px 20px #0000003b;
  border-radius: 15px;
  z-index: 6000;
  box-sizing: border-box;
  padding: 10px 25px 20px 25px !important;
  display: flex;
  flex-direction: column;
}

.modal_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.modal_header h1 {
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  color: #00367B;
  margin-bottom: 10px;

}

.modal_content {
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

/* .modal_content *:not(:last-child) {
  margin-bottom: 1.5rem;
} */

@media (max-width: 768px) {
  .modal_container {
    max-height: 90vh;
    width: 95%;
  }
}
