.form__new__password{
    display: flex;
    background: #FFFFFF;
box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
border-radius: 20px;
    position: relative;
    padding: 2rem 3rem;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}   

.button__newPassword{
    position: relative;
    top: 40px;
}