.cards-info-equipments{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.cards-info-equipments img{
    width: 350px;
    margin: 100px 15px 0px 15px;
}

.subtitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    color: #38A1FF;
}


  @media (max-width: 700px) {
    .cards-info-equipments {
      display: flex;
      margin: 10px;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 350px) {
    .cards-info-equipments img{
width: 80%;
    }
  }