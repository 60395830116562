.subtitle__quoter {
  margin-top: 20%;
  width: 100%;
  text-align: center;
  color: #00367b;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
}
.subtitle__quoter__warranty {
  margin-top: 10%;
  width: 100%;
  text-align: center;
  color: #00367b;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
}

.box__cards__quoter {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.box__cards__quoter h2 {
  font-size: 26px;
  font-weight: 600;
}
.container__text__quoter p {
  font-size: 18px;
}
.cards {
  width: 284px;
  height: 390px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
}

.button-quoter {
  width: 110px;
  height: 35px;
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  color: #fff;
}

.button-quoter__others {
  width: 110px;
  height: 35px;
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  color: #fff;
  position: relative;
  top: 20px;
}

.container__text__quoter {
  width: 100%;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
}

.cards img {
  width: 50%;
  height: 120px;
  margin: 20px;
}
.cards h5 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #00367b;
  text-align: center;
}
.container__blue {
  display: flex;
  background: #d8e8ff;
  border-radius: 20px 20px 0px 0px;
  width: 270px;
  height: 20px;
}

.container__cards {
  position: absolute;
  width: 270px;
  height: 25px;
  top: 10%;
  background: #d8e8ff;
  border-radius: 20px 20px 0px 0px;
}

.btn__quoter {
  display: flex;
  width: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.img__quoter {
  text-align: center;
}
.img__icon {
  width: 66%;
}
@media (max-width: 760px) {
  .subtitle__quoter {
    margin-top: 20%;
  }
  .img__icon {
    width: 54%!important;
}
}

@media (max-width: 400px) {
  .subtitle__quoter {
    margin-top: 25%;
  }
}
@media (max-width: 320px) {
  .subtitle__quoter {
    margin-top: 35%;
  }
}

@media (max-width: 500px) {
  .subtitle__quoter__warranty {
    margin-top: 12vh;
  }
}
