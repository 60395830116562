/* .modal-warranties {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5200;
  background-color: rgba(0, 0, 0, 0.8);
} */

.modal-warranties__container {
  /* z-index: 6000;
  box-sizing: border-box; */
  width: 100%;
  /* padding: 0 1rem;
  padding-bottom: 0.5rem; */
}

.modal-warranties__header h1 {
  font-weight: 600;
  font-size: 1.5rem;
  color: #304f97;
  margin: 0 !important;
}

.step_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0.5rem; 

}

.step_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.4em;
  margin-bottom: 2em;
}

.step_content_title {
  font-size: 1.3em;
  color: var(--secondary-color);
  margin: 0;
  /* margin-bottom: 0.5em; */
}

.step_content_dates {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
}

.step_content_dates label {
  min-width: 146px;
  flex: 1;
}

.step_actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.step_notification_container {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.step_notification_container button {
  margin-top: 1em;
}

.step_notification_title {
  font-size: 1.5em;
  color: #304f97;
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
}

.step_notification_subtitle {
  color: #7e8084;
  font-size: 1.3em;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

.step_notification_spinner {
  border-left-color: #304f97;
  width: 60px;
  height: 60px;
  margin-top: 50px;
}

.step_results {
  background-color: rgb(246, 246, 249);
  color: rgb(126, 128, 132);
  margin-bottom: 20px;
  gap: 0;
  height: 100%;
  border-radius: 10px;
  padding: 10px;
}

.step_results .step_results_item:not(:last-child){
  border-bottom: #7e8084 1px solid;
}

.step_results_item {
  width: 95%;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  padding: 1em;
  flex-wrap: wrap;
  row-gap: 1em;
  column-gap: 2em;
  justify-content: space-between;
}

.step_results_item p {
  width: 140px;
  color: #7e8084 !important;
  margin: 0;
}

.step_results_item span {
  flex-shrink: 0;
  max-width: 100%;
  font-weight: 600;
}

@media (max-width: 768px) {
  /* .modal-warranties__container {
    max-height: none;
    padding: 0;
    padding-bottom: 0.5rem;
  } */

  .modal-tenders__container button {
    justify-self: flex-end;
  }

  .step_container {
    height: 85vh;
    -webkit-overflow-scrolling: touch;
  }

  .step_notification_container {
    font-size: 14px;
  }

  .step_results_item {
    font-size: 14px;
  }
}
