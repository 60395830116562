.product_container {
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  padding-bottom: 1rem;
}


.product_info_container {
  width: 75%;
}
.product_info_container > *,
.product_info_glosa p {
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0.6rem;
}

.product_info_container h2 {
  font-size: 2.4em;
  font-weight: bold;
  font-weight: 500;
  color: black;

}

.product_info_container .item_name{
  color: var(--primary-color);
  font-size: 0.9rem;
  font-weight: 600;
}

.product_info_container h3 {
  font-size: 1.2em;
  color: #1a1a1a;
}

.product_info_container p,
.product_info_glosa p {
  font-size: 1em;
  margin-bottom: 0.6rem;
  color:#1a1a1a;
}

.product_left_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}

.product_actions_container{
  display: flex;
  gap: 0.5rem;
}

.product_action  {
  color: #fff;
  padding: 0.5rem;
  border-radius: var(--border-radius);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
@media (max-width: 821px){
  .content__title__product{
    align-items: center;
    flex-direction: column;
  }
}
.product_actions_container svg:hover {
  color: #fff;
}
.product_price_container{
  display: flex;
}
.product_price_container h1 {
  margin: 0;
  font-weight: 500;
  font-size: 1.7em;
}

.shrink .product_info_glosa p {
  color: #1a1a1a;
}
.product_info_date {
  font-size: 1.2em !important;
  color: gray;
}

.edit_modal_subtitle {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 19px;
  color: #5479b1;
}

.edit_modal_actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.edit_modal_container{
  height: 85vh;
  width: 100%;
  padding-right: 1rem;
}

.editModal_input_container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.content__title__product{
  display: flex;
  justify-content: space-between;
}