.btn__cotizador__principal {
  font-weight: 500;
  font-size: 20px;
  position: relative;
  top: -16vh;
  right: 15vw;
  color: #0606f9;
  border: none;
}

.container__button__principal button {
  background: #ffffff;
  border: 1px solid #0606f9;
  border-radius: 10px;
  margin-top: 24px;
  padding: 10px;
  width: 100%;
}
.container__button__principal {
  margin-top: 22px;
}

.banner__newsletter__second {
  display: flex;
  width: 100%;
  height: 90px;
  background: linear-gradient(180deg, #3595d5 4.48%, #1251ae 80.21%);
  margin-top: 12vh;
  flex-direction: column;
}

.container__info {
  display: flex;
  width: 100%;
  margin: 0 auto;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  position: relative;
  top: -7vh;
}

.div__background2 {
  display: flex;
  width: 80%;
  background: rgba(240, 241, 249, 0.8);
  border-radius: 50px;
  flex-direction: column;
}
.div__background2 h2 {
  display: flex;
  justify-content: center;
  color: #1251ae;
  font-weight: 600;
  font-size: 40px;
  line-height: 61px;
  text-align: center;
  margin-top: 3%;
}

.div__background2 p {
  color: #5579a7;
  font-weight: 400;
  font-size: 26px;
  line-height: 29px;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.div__container__cards {
  position: relative;
  display: flex;
  width: 100%;
}

.subtitle {
  background: #fbfbfc;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  z-index: 4;
}

.container__subtitle {
  width: 320px;
  height: 155px;
  background: #fbfbfc;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

/* CARDS */
.container__all__cards {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 3;
}

.div__cards__info {
  width: 320px;
  height: 155px;
  background: #fbfbfc;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  z-index: 3;
  grid-gap: 50px;
  gap: 50px;
}

.container__info__icon {
  display: flex;
  width: 339px;
  height: 178px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.explain__cards__container {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: 50px;
  flex-wrap: wrap;
}
.container__title__info {
  display: flex;
  position: relative;
  top: -60px;
  left: -160px;
  background: #1251ae;
  border-radius: 10px;
  color: #fff;
  padding: 5px;
}

.info__cards__container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  grid-gap: 50px;
  margin-left: 5%;
  gap: 50px;
  padding: 5%;
  z-index: 5;
  flex-wrap: wrap;
}

.text__our__products {
  width: 261px;
  height: 39px;
  left: 926px;
  top: 3584px;
  color: #1251ae;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  margin-top: -25%;
}
.text__our__products__two {
  color: #5579a7;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  margin: 0px 10px 10px 10px;
}

.hand__contact {
  display: flex;
  margin-top: -10%;
  width: 100%;
  justify-content: flex-end;
}
.container__contact {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-top: 10%;
}

.container__info__info {
  display: flex;
  width: 100%;
  background: #ffffff;
  border-radius: 50px;
  flex-direction: column;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  padding: 1%;
  margin-left: 10%;
}
.container__info__info h2 {
  display: flex;
  justify-content: center;
  color: #1251ae;

  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 61px;
  margin-top: 1%;
  text-align: center;
}

.container__info__info p {
  /* width: 650px; */
  /* height: 245px; */
  font-style: normal;
  padding: 15px;
  font-weight: 300;
  font-size: 18px;
  line-height: 35px;
  text-align: center;
  color: #000000;
}
.container__info__info p strong {
  font-weight: 600;
}
.container__our__products button a{
  color: white;
}
.container__our__products button a:hover{
  color: #1251ae;
}
.button__contact__info {
  width: 240px;
  height: 48px;
  background: linear-gradient(180deg, #3595d5 4.48%, #1251ae 80.21%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 20px;
  margin-top: -5%;
  position: relative;
  top: -23px;
}

.container__button__contact {
  display: flex;
  width: 100%;
  justify-content: center;

}

.container__button__contact .button__blue {
  font-size: 18px;
  width: 23rem;
}

@media (max-width: 1300px) {
  .hand__contact img {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .hand__contact {
    display: none;
  }
  .container__info__info {
    margin-right: 0;
    margin-left: 0;
    width: 90%;
    height: auto;
  }
  .container__info__info p {
    width: auto;
    font-size: 18px;
    line-height: 22px;
    height: auto;
  }
  .container__info__info h1 {
    font-size: 40px;
  }
  .button__contact__info {
    position: relative;
    top: -20px;
  }
}

@media (max-width: 500px) {
  .div__background2 h1 {
    font-size: 30px;
  }
  .div__background2 p {
    font-size: 17px;
    padding: 10px;
  }
  .container__title__info {
    display: none;
  }
  .text__our__products {
    margin-top: 0;
  }
  .container__info__info h1 {
    font-size: 25px;
  }
  .container__info__info p {
    font-size: 15px;
  }
  .div__background2 {
    width: 90%;
  }
}

@media (max-width: 300px) {
  .container__info__info {
    height: 500px;
  }
  .container__info__info h1 {
    line-height: 30px;
  }
}
