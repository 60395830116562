.banner-faqs {
   min-height: 60px;
   width: 70%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   box-sizing: border-box;
   padding: 1rem 5rem;
   background-color: #f8f9fd;
   /* box-shadow: 0px 5px 20px #0000003b; */
   border: 2px solid #eef2fd;
   border-radius: 22px;
   margin: 3rem auto;
}

.banner-faqs h2 {
   font-size: 1.2rem;
}
