.validation_container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.loading_container h1 {
  font-weight: 600;
  font-size: 2.1em;
  text-align: center;
  color: #031e44;
  margin: 0;
  margin-bottom: 37px;
}

.payment_subtitle{
  font-weight: 500;
  color: var(--secondary-color);
  margin: 0;
  font-size: 1.4rem;
}

.validation_img_container {
  width: 100%;
}

.validation_img_container img{
  width: 100%;
}
