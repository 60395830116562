.cards-info-otros{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  /* margin-top: 10%; */
}

.container-cards-otros{
  display: flex;
  flex-direction: column;
  width: 50%;
}
.container-cards-otros h5{
  text-align: center;
  color: #38a1ff;
}
.container-cards-otros p{
  text-align: center;
}
@media (max-width: 1280px) {
  .container-cards-otros{
margin-top: 20px;
  }
}
@media (max-width: 1000px) {
  .container-cards-otros{
width: 70%;
  }
}