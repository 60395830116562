.img__executive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container__executive .container__image__myexecutive {
  width: 100%;
  display: flex;
  justify-content: center;
}
.screen__container__executive {
  height: 70vh;
  max-width: 120rem;
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.e-company_main_container {
  width: 100%;
}
.container__image__myexecutive img {
  width: 35%;
}
.container__company__selector .input__container {
  text-align: start;
}
.myexecutive {
  /* margin-top: 15%; */
  width: 580px;
  height: 280px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.container__executive {
  display: flex;
  background: #f0f1f9;
  border-radius: 10px 10px 0px 0px;
  height: 53px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.info__executive {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-left: 0;
  padding: 0;
}
.info__executive img {
  padding-right: 10px;
}
.text__executive {
  padding: 20px;

  text-align: center;
}

.container_all_info {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.container__info__exe img {
  margin-right: 1rem;
}
.container__info__all__exe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 3rem;
}
.container__info__exe {
  display: flex;
  align-items: center;
}
.container__info__exe p {
  margin-bottom: 0;
}
.text__final {
  color: #00367b;
  position: relative;
  top: -30px;
  left: 45px;
  cursor: pointer;
}

.container__company__selector {
  width: 100%;
}
.container__company__selector select {
  width: 250px;
  background-color: #f0f1f9;
  border: none;
}
@media (max-width: 700px) {
  .myexecutive {
    margin-top: 3rem;
    width: 250px;
    height: 290px;
  }
  .container__executive {
    width: 250px;
  }
  .container_all_info {
    margin-top: 7rem !important;
    flex-direction: column;
  }
}
.not__company {
  font-size: 30px;
  text-align: center;
  margin-left: 0;
  color: #00367b;
  display: flex;
  align-items: center;
}

.ej_asig {
  width: 50%;
  margin-top: 7rem;
}
.ej_asig p {
  font-size: 2rem;
}

.ej_asig strong {
  color: #00367b;
  cursor: pointer;
  transform: translateZ(20px);
  transition: color 0.4s;
  text-decoration-line: underline;
}
.ej_asig strong:hover {
  color: #3c78c7;
  transition: background 0.3s;
  cursor: pointer;
}
.ej_asig a {
  display: inline;
}
