.bg-main {
  background: linear-gradient(180deg, #1251ae 4.48%, hsl(204, 66%, 52%) 80.21%);
}
.big-container {
  background-image: url(../../../../assets/images/linea3.svg);
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}
.medium-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.subtitle-img-1 {
  width: 100%;
  font-size: 50px;
  margin-bottom: 30px;
  align-items: flex-start;
  -webkit-text-stroke: 1px;
  text-shadow: 2px 2px 2px #4f4a4a;
}
.contact__container__index {
  /* width: 100%; */
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
  padding: 10rem 0 4rem 0;
}
.contenedor-main {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.content-img-1 {
  color: white;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 70px;
}
.text-img-1-end {
  display: flex;
  width: 100%;
  font-size: 25px;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
  line-height: 35px;
}
@media (max-width: 1100px) {
  .contact__container {
    padding: 5rem 0;
  }
  .contenedor-main {
    flex-direction: column;
  }

  .form-contact-index {
    margin-left: 0;
    width: 100%;
  }
  .contact__container__index {
    padding: 0 0 4rem 0;
    width: 80%;
  }
}
@media (max-width: 800px) {
 
  .contenedor-main img{
    width: 100%;
  }
  .contenedor-main {
    flex-direction: column;
  }
  .bg-main {
    padding-top: 7rem;
    /* padding-bottom: 7rem; */
    height: auto;
  }
  .big-container {
    background-position: initial;
}
  .form-contact-index {
    margin-left: 0;
    width: 110%;
  }
  .subtitle-img-1 {
    font-size: 37px;
    text-align: center;
  }
  .text-img-1-end {
    font-size: 18px;
  }
}
