.container {
  width: 100%;
}

.title_Container {
  border: var(--border);
  border-radius: var(--border-radius);
  background: #f8f9fd;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title_Container .title {
  font-weight: bold;
  color: var(--primary-color);
}

.title_Container svg {
  cursor: pointer;
  color: var(--secondary-color);
  border-radius: 5px;
}

.menu_Container {
  position: absolute;
    width: 90%;
    /* top: 4.3rem; */
    background-color: #fff;
    box-shadow: var(--box-shadow);
    z-index: 1;
}

.menu_Container ul {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  padding-inline-start: 0 !important;
  padding: 10px 0;
}

.menu_Container ul li {
  list-style: none;
  padding: 0.5rem 0;
  width: 100%;
  color: gray;
  cursor: pointer;
  font-weight: bold;
}

.menu_Container ul li:hover {
  color: var(--primary-color);
}
/* .menu_Container ul li:not(:last-child) {

    border-bottom: 1px solid rgba(204, 198, 198, 0.521);
    padding-bottom: 10px;
  
  } */
