.contact__container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
  /* margin-left: 15vw;
   margin-right: 15vw; */
}

.form-contact {
  width: 54%;
  max-width: 1200px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 12px;
  transition: height 2s ease;
  text-align: center;
  box-sizing: border-box;
  padding: 20px 60px;
  margin-left: 10rem;
}

.form-contact__text h2 {
  color: #1251ae;
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  margin-bottom: 3rem;
  position: relative;
  top: 10px;
}
.form-contact__text__initial {
  color: #1251ae;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
}

.form-contact__text p {
  color: #5c5c5c;
}

.form-contact__form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.form-contact-index {
  width: 75%;
  max-width: 1200px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 12px;
  transition: height 2s ease;
  text-align: center;
  box-sizing: border-box;
  padding: 20px 20px;
  margin-left: 5rem;
}
.form-contact__inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.form-contact__inputs__index {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 0.1rem;
}
.form-contact__text__initial h2 {
  font-size: 1.3rem;
  font-weight: 700;
}

.form-contact__optional_inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  height: fit-content;
}

.form_contact_second_title {
  font-size: 1rem;
  color: var(--secondary-color);
  margin: 0;
}

.form-contact__textarea {
  flex: 1;
  width: 100%;
  height: 180px;
}

.form-contact__optional_inputs textarea {
  /* width: calc(100% - 43px); */
  width: 100%;
  min-height: 200px;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font-size: 0.85rem;
  font-weight: 500;
  letter-spacing: 0px;
  color: #08254a;
  padding-left: 15px;
  outline: none;
  font-family: var(--primary-font);
  padding-top: 1rem;
  resize: none;
}

.form-contact__button {
  width: 100%;
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
}

/*  Casos */

.form-contact__file_input::-webkit-file-upload-button {
  color: #ffffff;
  padding: 7px;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0px;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  font-family: var(--primary-font);
  cursor: pointer;
  outline: none;
}

.case_licitacionPrivada_container {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.case__cotizacion_container {
  width: 100%;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.case__cotizacion_container__radio {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
}

.case__cotizacion_container__radio label {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.case__cotizacion_container__radio input {
  margin: 0;
  margin-right: 0.3rem;
}

.incomplete_field_warning {
  color: red;
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
  margin-top: 0.4rem;
}

@media (max-width: 900px) {
  .form-contact {
    width: 90%;
    padding: 20px 15px;
  }

  .hand__contact {
    display: none;
  }
  .form-contact {
    margin-left: 0rem;
  }
}

@media (max-width: 700px) {
  .form-contact-index-wsp {
    width: 76%!important;
    margin-left: 0rem!important;
}
  .form-contact__inputs {
    display: flex;
    flex-direction: column;
  }
  .case_licitacionPrivada_container,
  .form_contact_select {
    width: 100%;
  }
}
.check__index {
  display: flex;
  color: black;
}
.check__index p {
  font-size: 0.8rem;
  width: 90%;
}
.check__index input {
  height: 20px;
  width: 10%;
}
.invalid_phone {
  color: red;
  font-size: 1rem;
  display: flex;
  margin: 0;
}

.first__number__fono {
  border: 1px solid gray;
  border-radius: 6px;
  margin-top: 2.3rem;
  padding: 0.23em;
}

.form-contact-index-wsp {
  width: 32%;
  max-width: 1200px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 12px;
  transition: height 2s ease;
  text-align: center;
  box-sizing: border-box;
  padding: 20px 20px;
  margin-left: 5rem;
}
.css-79ws1d-MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

#text{
  display: none;
}

.MuiModal-root.css-8ndowl {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  display: flex  !important;
  justify-content: center !important;
  align-items: center !important;
 
}

