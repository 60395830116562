
.container__storeCompany{
  max-width: 120rem;
  width: 60%;
  margin: 0 auto;
  padding-top: 60px;
}

.store-company__title {
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 1.8rem;
  color: var(--primary-color);
  text-align: center;
}

.store-company__form {
  width: 100%;
  max-width: 1100px;
  min-height: 470px;
  border-radius: 20px;
  padding: 20px;
  border: none;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
}


.store-company__form__title {
   color: #00367b;
   font-family: "Inter" sans-serif;
   text-align: center;
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 19px;
}

.title__storeCompany {
  color: #00367b;
  font-family: "Inter" sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 30px;
}

@media (max-width: 520px ) {
  .title__storeCompany{
line-height: 40px;
  }
}