.form__register__data {
  max-width: 1200px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 20px;
  transition: height 2s ease;
  text-align: center;
  box-sizing: border-box;
  padding: 50px 50px 50px 50px;
}

.btn-close-modalSii {
  width: 20px;
}
.section__modal__sii {
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.section__modal__sii span {
  color: #1251ae;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 25px;
  line-height: 61px;
  background: #fff;
  border-radius: 20px;
  padding: 1%;
  line-height: 30px;
}

.section__modal__sii h3 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 10px;
  line-height: 61px;
  background: #fff;
  border-radius: 10px;
  padding: 5px;
  line-height: 27px;
  cursor: pointer;
}

.container-wizzard {
  display: flex;
  flex-direction: column;
  width: 50%;
  /* margin-top: -30px; */
}
.contact__container__user {
  width: 50%;
  transition: all 1s ease;
}

.button__next {
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  border: none;
  height: 40px;
  width: 130px;
  color: #ffffff;
  font-size: 1rem;
  letter-spacing: 0px;
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  font-family: var(--primary-font);
  cursor: pointer;
  outline: none;
}

:disabled.button__next {
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  border: none;
  height: 40px;
  width: 130px;
  color: #ffffff;
  font-size: 1rem;
  letter-spacing: 0px;
  background: gray;
  font-family: var(--primary-font);
  cursor: pointer;
  outline: none;
}

.container__button__next {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 3vh;
}

.send__login {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
}

.btn__back__register {
  width: 80px;
  height: 40px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  background: #ffffff;
  border: 1px solid #1251ae;
  border-radius: 10px;
  color: #1251ae;
  margin-left: 1%;
}
.btn__back__register__later {
  width: 150px;
  height: 40px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  background: #ffffff;
  border: 1px solid #1251ae;
  border-radius: 10px;
  color: #1251ae;
  margin-left: 1%;
}
.resumen {
  width: 376px;
  height: 400px;
  background: #f8f9fd;
  border: 1px solid #9f9f9f;
  border-radius: 20px;
}

.container-resumen {
  display: flex;
  flex-direction: row;
}

.info__resumen {
  margin-left: 10%;
  margin-top: 5%;
  width: 187px;
  height: 50px;
  color: #00367b;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
}

.name__resumen {
  margin-top: 25px;
  margin-left: 10%;
  font-family: "Inter", sans-serif;
  color: #7a7a7a;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.container__info__resumen {
  width: 100%;
  display: flex;
  color: #9f9f9f;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  flex-direction: column;
  line-height: 20px;
  margin-inline: 10%;
}

.text__password__unmatching {
  width: 100%;
  display: flex;
  position: relative;
  font-size: 15px;
  color: red;
  justify-content: center;
}

.select__person__company {
  flex: 3;
  position: relative;
}
/* REGISTER CLIENT */
.form__container__register {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 20px;
  padding: 70px;
}

.register__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;
  margin-top: 17vh;
  background-color: var(--background-color);
  flex-direction: column;
}

.register__title {
  display: flex;
  width: 100%;
  font-size: 24px;
  color: #304f97;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  margin-bottom: 15px;
  margin-top: -15px;
  flex-direction: row;
  justify-content: center;
}
.text-register-title {
  width: 100%;
  text-align: center;
}

.register-form__container {
  display: grid;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  grid-column-gap: 3rem;
  column-gap: 3rem;
  grid-template-columns: minmax(240px, 1fr) minmax(240px, 1fr);
}
.register-form__container select {
  margin-top: 6.5vh;
}
.register__text-content {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-left: 3rem; */
}

.register__text-content__text {
  color: #304f97;
  width: 100%;
}

.register__text-content__title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.register__text-content__text h1 {
  font-size: 42px;
  font-weight: 600;
  color: #304f97;
  margin: 0;
}

.register__text-content__text p {
  font-size: 1.4rem;
  font-weight: 500;
  color: #5c5c5c;
}

.register__text-content__text h1:first-child {
  margin-top: 0;
}

.register__text-content__img {
  width: 90%;
  height: 400px;
  background-image: url("../../assets/images/hero.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  margin-top: 20px;
}

.register__modal {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  border: 1px solid red;
}

.register-form-principal-two {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  line-height: 35px;
  /* padding: 8vh 0 8vh 0; */
  padding-bottom: 10px;
}

.acept-check {
  display: flex;
  width: 100%;
  text-align: justify;
  margin-bottom: -20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.acept-check input {
  width: 15px;
  margin-right: 1%;
  cursor: pointer;
}
.acept-check p {
  display: flex;
  width: 100%;
  text-align: center;
}

/* .wizzard{
  display:flex;
  background-color: #D8E8FF;
  height: 16px;
  border-radius: 30px;
} */

.text-wizzard {
  display: flex;
  justify-content: space-around;
}

.checkbox__text__register {
  margin-left: 1%;
  color: #0606f9ba;
  cursor: pointer;
}

.password_input {
  flex: 3;
  width: 100%;
  position: relative;
  top: 51px;
}
.container__inputs__sii {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
@media (max-width: 1000px) {
  .register__container {
    margin-top: 15vh;
    margin-bottom: 40px;
  }
  .modal__clave {
    width: 100%;
  }
  .logoSll {
    width: 28% !important;
  }
  .register__text-content {
    display: none;
  }
  .container__text__sii {
    order: 1;
  }
  .container__inputs__sii {
    order: 2;
  }
}

@media (max-width: 700px) {
  .register-form__container {
    display: flex;
    flex-direction: column;
  }
}

/* COMPLETAR REGISTER */

.create__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;
  margin-top: 17vh;
  background-color: var(--background-color);
  flex-direction: column;
}

.form__container__create {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 20px;
  padding: 20px;
}

.inputs__create {
  display: flex;
  width: 100%;
  margin-top: 5%;
}
.inputs__create input {
  width: 180px;
  height: 40px;
}

.inputs__create__two {
  display: flex;
  width: 100%;
}
.inputs__create__two input {
  width: 280px;
  height: 40px;
}

.inputs__create__three {
  display: flex;
  width: 100%;
}
.inputs__create__three input {
  width: 280px;
  height: 40px;
}

.number__input__number input {
  width: 80px;
  height: 40px;
}

.number__input__office input {
  width: 80px;
  height: 40px;
}
.number__input__office {
  position: relative;
  left: -15px;
}
.number__input__number {
  position: relative;
  left: 10px;
}

.select-regiones {
  flex: 3;
  width: 15%;
  border-radius: 10px;
  height: 40px;
  margin-top: 38px;
  position: relative;
}
.select-comunas {
  flex: 3;
  height: 40px;
  border-radius: 10px;
  margin-top: 38px;
  position: relative;
}
.create__title {
  display: flex;
  width: 100%;
  font-size: 24px;
  color: #304f97;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  /* margin-top: -40%; */
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.create__subtitle {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.container__title__create {
  display: flex;
  width: 100%;
  position: relative;
  top: 5%;
  flex-direction: column;
}

.container__buttons__create {
  display: flex;
  width: 50%;
  margin-left: 8%;
  justify-content: flex-end;
}

.btn-back__create {
  width: 98px;
  height: 40px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  background: #ffffff;
  border: 1px solid #1251ae;
  border-radius: 10px;
  color: #1251ae;
  margin-left: 1%;
}

.container__button__create {
  display: flex;
  justify-content: flex-end;
  width: 90%;
}

.container-lugage {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.container-lugage img {
  width: 13%;
  margin-top: -15%;
  margin-left: 13%;
}
.btn-create {
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
}
.flex_auto {
  flex: auto;
}

.container-btn-later {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-top: -13px;
}

@media (max-width: 700px) {
  .create__title {
    font-size: 30px;
  }
  .contact__container__user {
    width: 100%;
    transition: all 1s ease;
  }
  .container__text__sii {
    width: 100% !important;
  }
  .container__text__sii {
    align-items: center !important;
  }
  .contenedor__modal__clave {
    align-items: center;
  }
  .button__save__clave {
    font-size: 0.8rem !important;
    width: 69% !important;
  }
  .button__omitir__clave {
    font-size: 0.8rem !important;
    width: 69% !important;
  }
}

.button__omitir__clave {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 38px;

  border-radius: 100px;
  border: 1px solid #0606f9;
  color: #fff;
  transform: translateZ(20px);
  transition: color 0.4s;
  font-weight: 600;
  background-color: #0606f9;
}

.button__omitir__clave::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}
.button__omitir__clave:hover {
  background: #fff;
  transition: background 0.6s;
  color: #0606f9;
}
.container__btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}
.container__text__sii {
  display: flex;
  align-items: end;
  flex-direction: column;
  justify-content: center;
  width: 87%;
}
