.whatsapp-button-styles {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 60px;
  right: 15px;
  border: none;
  background-color: transparent;
  background-image: url('../../assets/images/whatsapp.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  z-index: 1000;
  cursor: pointer;
  outline: none;
}