.square-button--default-styles {
  padding: 0.7rem 1rem;
  color: rgb(255, 255, 255);
  font-size: 0.94em;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  border:none;
  font-weight: 100;
  margin-right: 5px;
  margin-left: 14px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  background: linear-gradient(180deg, #0099FF 0%, #0606F9 100%);
}
