.company_edit_contenedor {
  max-width: 120rem;
  width: 70%;
  margin: 0 auto;
}

.container__e-company_form_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 2rem;
}
.container__select__company {
  display: flex;
  width: 100%;
  /* margin-left: 8%; */
}
.container__select__company select {
  width: 100%;
  border: none;
  background-color: rgb(233, 242, 255);
}
.form__background {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  padding: 20px;
}

.Modal__background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  padding: 40px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.container__buttons__modalEdit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 20px;
  margin-top: 5%;
  z-index: 10;
}

.btn__modal__edit {
  width: 30%;
  border: none;
  background: linear-gradient(180deg, #3595d5 4.48%, #1251ae 80.21%);
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}

.button__active {
  border: none;
  background: none;
  color: #9f9f9f;
}

.container__allcompanys {
  width: 100%;
  margin-top: 10%;
}

.card__company {
  display: flex;
  width: 400px;
  height: auto;
  background: #ffffff;
  border-radius: 15px;
  margin-top: 20px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.not__company {
  font-size: 30px;
  text-align: center;
  margin-left: 18%;
  color: #00367b;
}

.container__button__add__newCompany {
  position: relative;
  width: 100%;
  margin-top: 13%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
}

.button__add__newCompany {
  background: #ffffff;
  border: none;
  border-radius: 10px;
  color: #1251ae;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  left: 80px;
}

.container__data {
  padding: 20px;
}

.container__aprobado {
  position: relative;
  width: 415px;
  height: 58px;
  background: #d8e8ff;
  opacity: 0.4;
  border-radius: 0px 0px 20px 20px;
  top: 22px;
  z-index: 1;
}
.container__aprobado h6 {
  text-align: center;
  margin-top: 5px;
  color: #000000;
  z-index: 3;
}
.container__aprobado p {
  text-align: center;
  color: #000000;
}

.container__img__company {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.data__member {
  background: #ffffff;
  border: none;
  border-radius: 10px;
  color: #1251ae;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  left: 80px;
}
.data__member img {
  width: 80%;
}
.button__add__newCompany img {
  width: 83%;
}
.edit-company-screen {
  gap: 3em;
}

.e-company_title_container h1 {
  font-weight: bold;
  font-size: 1.8rem;
  color: var(--primary-color);
}

.e-company_selector_container {
  width: 30%;
  min-width: 400px;
}

.loading_data_container {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: -1.5rem;
  width: 400px;
  gap: 1rem;
  color: var(--primary-color);
}

.data__company {
  display: flex;
  height: 60px;
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
  justify-content: flex-start;
  align-items: center;
}
.name__company {
  color: #00367b;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.state__company {
  color: #9f9f9f;
  font-weight: 200;
  font-size: 20px;
  line-height: 30px;
}
.data__company p {
  margin-top: 15px;
  /* margin-left: -10%; */
}
.e-company_form_container {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.e-company_form_container .screen_second__title {
  margin-top: 1rem;
  font-size: 1.5em;
}

.e-company_cards_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.company_edit_cards {
  display: flex;
  width: 70%;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
}

.e-company_cards_container h4 {
  font-size: 20px;
  color: #00367b;
  text-align: center;
}

.e-company_owners_table__container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  margin-top: 2%;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  margin-top: 60px;
}
@media (max-width: 515px) {
  .container__select__company button {
    position: relative;
    left: -10px;
  }
  .e-company_cards_container {
    align-items: center;
  }
}

@media (max-width: 1300px) {
  .e-company_cards_container {
    margin-top: 30px;
    align-items: center;
  }
}
@media (max-width: 1160px) {
  .e-company__cards_container__card {
    width: 390px;
    height: 520px;
  }
  .container__aprobado {
    width: 390px;
    height: 58px;
    top: 13px;
  }

  .e-company_owners_table__container {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .data__company {
    width: 410px;
  }
  .e-company_form_container {
    width: 410px;
  }
  .container__select__company button {
    left: -10px;
  }
  .company_edit_cards {
    width: 100%;
  }
}
.MuiPaper-root{
  font-family: var(--primary-font) !important;
}
@media (max-width: 515px) {
}
@media (max-width: 500px) {
  .e-company_main_container {
    margin-right: 20px;
    margin-left: 20%;
  }
}
@media (max-width: 480px) {
  .data__company {
    width: 320px;
  }
  .state__company {
    font-size: 15px;
    margin-right: 20px;
  }
  .e-company__cards_container__card {
    width: 310px;
    height: 420px;
  }
  .container__aprobado {
    width: 310px;
    height: 30x;
    top: 15px;
  }
  .container__img__company img {
    width: 60%;
  }
  .e-company_main_container {
    margin-right: 0;
    margin-left: 0;
    padding-left: 10%;
    padding-right: 20px;
  }
}

@media (max-width: 370px) {
  .data__company {
    width: 240px;
  }
}
@media (max-width: 515px) {
  .e-company_form_container {
    width: 300px;
  }
  .data__company {
    width: 300px;
  }
}

@media (max-width: 400px) {
  .e-company_cards_container {
    width: 300px;
  }
}
@media (max-width: 370px) {
  .e-company_cards_container {
    width: 270px;
  }
  .e-company_form_container {
    width: 270px;
  }
  .data__company {
    width: 270px;
  }
  .container__select__company select {
    width: 70%;
  }
  .container__select__company button {
    left: -45px;
  }
}
