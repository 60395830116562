.welcome-screen {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #304f97;
}
.welcome-screen__third-title {
  width: 75%;
  text-align: center;
}
.welcome-screen__container {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 115px;
}
.p_fly {
  margin-top: 2rem;
  font-size: 1.3rem;
}
.welcome-screen-buttons {
  display: grid;
  max-width: 120rem;
  width: 75%;
  margin: 0 auto;
  gap: 2rem;
  grid-auto-rows: 5rem;
  margin-top: 1%;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
}
.button__welcome__blue {
  color: white;
  font-weight: 700;
  font-size: 1.1rem;
  background-color: #0606f9;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  cursor: pointer;
  padding: 1rem 1.5rem;
}
.button__welcome__blue:hover {
  background: white;
  transition: background 0.3s;
  color: #0606f9;
  border: 1px solid #0606f9;
}
.button__welcome__blue::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}
.img__welcome {
  width: 16rem;
}
.p_fly_2 {
  font-size: 1.3rem;
}
