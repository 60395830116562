.faqs-screen {
   width: 100%;
   padding: 9rem;
   text-align: center;
   margin-bottom: 80px;
   margin: auto;
   box-sizing: border-box;
}

.faqs-screen label {
   display: block;
   margin-top: 2.3rem;
}

.faqs-screen label span {
   font-weight: bold;
}

.faqs-screen label input {
   padding: 0.5rem 0;
   margin-left: 1rem;
   border-radius: 22px;
   /* outline: 1px solid red; */
   border: 1px solid #eef2fd;
   background-color: #eef2fd;
}
