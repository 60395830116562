.button--default-styles {
  height: 40px;
  width: 140px;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0px;
  border: 1px solid transparent;
  border-radius: 10px;
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  font-family: var(--primary-font);
  cursor: pointer;
  outline: none;
}
.button--default-styles:disabled {
  color: #a6a6a6;
  background: #f5f9ff;
}
button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #999999;
  cursor: default;
}
button:disabled:hover {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #999999;
  cursor: default;

}

.button__profile__login {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 149px;
  height: 40px;
  /* top: 20px; */
  border-radius: 100px;
  border: 1px solid #0606f9;
  color: #fff;
  transform: translateZ(20px);
  transition: color 0.4s;
  background-color: #0606f9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.button__profile__login:hover {
  background: white;
  transition: background 0.3s;
  color: #0606f9;
}
.button__profile__login::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}
.button__blue {
  position: relative;
  border-radius: 100px;
  border: 1px solid #0606f9;
  background-color: #0606f9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 149px;
  height: 40px;
  color: #fff;
  transform: translateZ(20px);
  transition: color 0.4s;
  font-weight: 600;
  z-index: 1;
}
.button__blue:hover {
  background: white;
  transition: background 0.3s;
  color: #0606f9;
}
.button__blue::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}
.button__gray {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 149px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid gray;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: gray;
  color: #fff;
  transform: translateZ(20px);
  transition: color 0.4s;
  font-weight: 600;
  z-index: 1;
}
.button__gray:hover {
  background: white;
  transition: background 0.3s;
  color: gray;
}
.button__gray::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}

.button__white {
  position: relative;
  display: flex;
  justify-content: center;
  width: 160px;
  height: 41px;
  font-weight: 600;
  top: 20px;
  border-radius: 100px;
  border: 1px solid #0606f9;
  color: #0606f9;
  transform: translateZ(20px);
  transition: color 0.4s;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  padding-top: 0.5rem;
}

.button__white::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}
.button__white:hover {
  background: #0606f9;
  transition: background 0.4s;
  color: #fff !important;
}
.button__white:hover a {
  background: #0606f9;
  transition: background 0.4s;
  color: #fff !important;
}


.button__action {
  position: relative;
  border-radius: 100px;
  border: 1px solid #053F63;
  background-color: #053F63;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 49px;
  font-size: 1.5rem;
  color: #fff;
  transform: translateZ(20px);
  transition: color 0.4s;
  font-weight: 600;
}
.button__action:hover {
  background: white;
  transition: background 0.3s;
  color: #053F63;
}
.button__action::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}
.button__action__suscribe {
  position: relative;
  border-radius: 100px;
  border: 1px solid #25D9D6;
  background-color: #25D9D6;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 49px;
  font-size: 1.5rem;
  color: #fff;
  transform: translateZ(20px);
  transition: color 0.4s;
  font-weight: 600;
}
.button__action__suscribe:hover {
  background: white;
  transition: background 0.3s;
  color: #25D9D6;
}
.button__action__suscribe::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}

.button__profile__login__google {
  position: relative;
  display: flex;
  justify-content: center;
  width: 10rem;
  height: 40px;
  /* top: 20px; */
  border-radius: 100px;
  border: 1px solid gray;
  transform: translateZ(20px);
  transition: color 0.4s;
  background-color: #fff;
}
.button__profile__login__google::before {
  position: relative;
  display: flex;
  justify-content: center;
  width: 149px;
  height: 40px;
  /* top: 20px; */
  border-radius: 100px;
  border: 1px solid #0606f9;
  color: #fff;
  transform: translateZ(20px);
  transition: color 0.4s;
  background-color: gray;
}
.button__profile__login__google:hover {
  background: gray;
  transition: background 0.3s;
  color: #fff;
}

.button__content {
  display: flex;
  gap: .4em;
}

.button__content__icon {
  height: 100%;
  width: 20px;
}

.button__content__text {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  margin-bottom: 2px;
}
@media (max-width: 821px) {
  .whatsapp-button-styles {
    right: 81px;
  }
}
.button__sesion{
  position: relative;
  display: flex;
  justify-content: center;
  width: 160px;
  height: 41px;
  font-weight: 600;
  top: 20px;
  border-radius: 100px;
  border: 1px solid white;
  color: white;
  transform: translateZ(20px);
  background-color: transparent;
  transition: color 0.4s;
  padding-top: 0.5rem;
}

.button__sesion:hover {
  background: white;
  transition: background 0.3s;
  color: #0606f9;
}
.button__sesion::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}